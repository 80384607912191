import fastdom from "fastdom";

import { HIDDEN, VISIBLE, onVisibilityChange } from "../dom-helpers/on-visibility-change";
import query from "../dom-helpers/query";
import { applyObjectFit } from "../object-fit";
import { addScrollListener } from "../scroll-track";
import "./hide-offscreen.scss";

const ONSCREEN_CLASS = "HideOffscreen__media--onscreen";

function hide(elements: HTMLElement[]) {
  fastdom.mutate(() => {
    elements.forEach(e => e.classList.remove(ONSCREEN_CLASS));
  });
}

function show(elements: HTMLElement[]) {
  fastdom.mutate(() => {
    elements.forEach(e => {
      e.classList.add(ONSCREEN_CLASS);
      applyObjectFit([e]);
    });
  });
}

interface OffscreenContainer {
  container: HTMLElement;
  children: HTMLElement[];
}

export default function initHideOffscreen(): void {
  query(".HideOffscreen--container")
    .map(container => {
      return {
        container,
        children: query(".HideOffscreen__media", container),
      } as OffscreenContainer;
    })
    .filter((offscreenContainer: OffscreenContainer) => offscreenContainer.children && offscreenContainer.children.length > 0)
    .forEach(offscreenContainer => {
      addScrollListener(offscreenContainer.container, event => {
        switch (event.type) {
          case "enter-buffer":
            show(offscreenContainer.children);
            break;

          case "leave-buffer":
            hide(offscreenContainer.children);
            break;
        }
      });
    });
}
