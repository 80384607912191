import DisplayContainer, { initDisplayContainer } from "../display-container";
import { addToLoop, unLoop } from "../loop";

initDisplayContainer();

/**
 * Scroll event type. One of:
 * "scroll": The viewport has scrolled without altering the visibility of the element.
 * "enter": The element has become visible (was previously out of view)
 * "leave": The element has become hidden (has moved out of view)
 */
export type ScrollEventType = "scroll" | "enter-buffer" | "enter" | "leave" | "leave-buffer";

export interface ScrollEvent {
  /**
   * Scroll type, as per ScrollEventType above.
   */
  type: ScrollEventType;
  /**
   * The element being tracked.
   */
  element: HTMLElement;
  /**
   * The position of the element within the current viewport
   */
  top: number;
  /**
   * The scroll position within the viewport in the range 0..1 where
   *    0 == top of element at bottom of viewport, and
   *    1 == bottom of element at top of viewport.
   * Note that enter and leave events may be outside of this range.
   */
  scrollPercent: number;
  /**
   * The height of the element being tracked.
   */
  elementHeight: number;
  /**
   * The height of the viewport within which the element is scrolling.
   */
  viewportHeight: number;
}

export type ScrollListener = (event: ScrollEvent) => void;

/**
 * Register a scroll listener for the given element, which scrolls relative to the
 * story container (either the viewport or the editor-viewport). The listener will
 * be invoked on entry or depature of the element to/form the viewport, as well as
 * for any scrolling that happens while the element is within the viewport.
 *
 * Adding the listener will fire an immediate event for the current state.
 * @param element Element to watch.
 * @param listener Callback to be invoked on scroll changes.
 */
export function addScrollListener(element: HTMLElement, listener: ScrollListener): void {
  if (scrolledElements.length === 0) {
    initScrollTracking();
  }
  const bucket = scrolledElements.find(entry => entry.element === element);
  if (bucket) {
    if (!bucket.listeners.find(cb => cb === listener)) {
      bucket.listeners.push(listener);
    }
  } else {
    scrolledElements.push({ element, lastVisibility: "not-visible", listeners: [listener] });
  }
  fireInitialEvent(element, listener);
}

function fireInitialEvent(element: HTMLElement, listener: ScrollListener): void {
  const { visibility, ...state } = getElementScrollInfo(element);
  switch (visibility) {
    case "visible":
      listener({ type: "enter-buffer", element, ...state });
      listener({ type: "enter", element, ...state });
      break;

    case "almost-visible":
      listener({ type: "enter-buffer", element, ...state });
      break;

    default:
      listener({ type: "leave", element, ...state });
      listener({ type: "leave-buffer", element, ...state });
      break;
  }
}

/**
 * Remove a previously registered scroll listener, which will no longer receive
 * notifications.
 * @param element
 * @param listener
 */
export function removeScrollListener(element: HTMLElement, listener: ScrollListener): void {
  const bucketIdx = scrolledElements.findIndex(entry => entry.element === element);
  if (bucketIdx !== -1) {
    const bucket = scrolledElements[bucketIdx];
    bucket.listeners = bucket.listeners.filter(cb => cb !== listener);
    if (bucket.listeners.length === 0) {
      scrolledElements.splice(bucketIdx, 1);
      if (scrolledElements.length === 0) {
        shutdownScrollTracking();
      }
    }
  }
}

/********************* Private Implementation Details *************************/
type VisibilityState = "not-visible" | "almost-visible" | "visible";

interface ScrolledElement {
  element: HTMLElement;
  lastVisibility: VisibilityState;
  listeners: ScrollListener[];
}

/**
 * Table of elements being tracked and their associated listeners.
 */
const scrolledElements: ScrolledElement[] = [];
/**
 * The scrollable viewport (either the window itself or the editor viewport)
 */
let scrollRoot: Element | Window;
/**
 * The vertical offset of the viewport from the top of the window.
 */
let viewportOffset = 0;
/**
 * Viewport resize observer (or null if unused)
 */
let viewportResizeObserver: ResizeObserver | null = null;

/**
 * Initialize global scroll-tracking. This is automatically invoked when the
 * first scroll listener is registered.
 *
 * Note: we listen for both actual scroll events and window resize events. The
 * latter is because resize events can result in effective scroll position
 * changes due to relayout, but will not trigger a scroll event themselves.
 */
function initScrollTracking(): void {
  const editorViewport = document.querySelector("#editor-viewport");
  if (editorViewport) {
    scrollRoot = editorViewport;
    viewportOffset = editorViewport.getBoundingClientRect().top;
    viewportResizeObserver = new ResizeObserver(onScrolled);
    viewportResizeObserver.observe(editorViewport, { box: "content-box" });
  } else {
    scrollRoot = window;
    viewportOffset = 0;
    window.addEventListener("resize", onScrolled, { passive: true });
  }

  scrollRoot.addEventListener("scroll", onScrolled, { passive: true });
  addToLoop(onLoop);
}

/**
 * Shutdown global scroll-tracking. Automatically invoked when the last
 * scroll listener is de-registered.
 */
function shutdownScrollTracking(): void {
  scrollRoot.removeEventListener("scroll", onScrolled);
  if (viewportResizeObserver) {
    viewportResizeObserver.disconnect();
    viewportResizeObserver = null;
  } else {
    window.removeEventListener("resize", onScrolled);
  }
  unLoop(onLoop);
}

/**
 * Defer actual processing of scroll events to the RAF loop. Which sounds
 * inefficient but in practice it makes scrolling _much_ smoother in Firefox
 * at least.
 */
let scrolled = false;
function onScrolled(): void {
  scrolled = true;
}

function onLoop(): void {
  if (scrolled) {
    scrolled = false;
    onScroll();
  }
}

/**
 * Event transition(s) based on previous and next visibility state, ie
 * SCROLL_STATE_MACHINE[lastVisibilty][nextVisibility] gives the events to fire.
 */
const SCROLL_STATE_MACHINE: Record<VisibilityState, Record<VisibilityState, ScrollEventType[]>> = {
  "not-visible": {
    "not-visible": [],
    "almost-visible": ["enter-buffer"],
    visible: ["enter-buffer", "enter"],
  },
  "almost-visible": {
    "not-visible": ["leave-buffer"],
    "almost-visible": [],
    visible: ["enter"],
  },
  visible: {
    "not-visible": ["leave", "leave-buffer"],
    "almost-visible": ["leave"],
    visible: ["scroll"],
  },
};

/**
 * Check the position of all elements and fire the associated listeners
 * when they're eligible. Note this currently checks all registered
 * elements regardless of the current scroll position, which could probably
 * be optimized.
 */
function onScroll(): void {
  scrolledElements.forEach(se => {
    const { top, visibility, scrollPercent, elementHeight, viewportHeight } = getElementScrollInfo(se.element);

    const eventData = {
      element: se.element,
      top,
      scrollPercent,
      elementHeight,
      viewportHeight,
    };

    const eventTypes = SCROLL_STATE_MACHINE[se.lastVisibility][visibility];
    eventTypes.forEach(type => {
      se.listeners.forEach(cb => cb({ type, ...eventData }));
    });
    se.lastVisibility = visibility;
  });
}

export function getElementScrollPercent(element: HTMLElement): number {
  return getElementScrollInfo(element).scrollPercent;
}

export interface IScrollPosition {
  top: number;
  visibility: VisibilityState;
  scrollPercent: number;
  elementHeight: number;
  viewportHeight: number;
}

export function getElementScrollInfo(element: HTMLElement): IScrollPosition {
  const { top, height: elementHeight } = element.getBoundingClientRect();
  const viewportHeight = DisplayContainer.getHeight();
  const range = viewportHeight + elementHeight;
  const scrollTop = top - viewportOffset;
  const scrollPercent = 1 - (elementHeight + scrollTop) / range;
  const isVisible = scrollPercent >= 0 && scrollPercent <= 1;
  const bufferSize = (viewportHeight * 0.5) / range;
  const isAlmostVisible = scrollPercent >= 0 - bufferSize && scrollPercent <= 1 + bufferSize;
  const visibility: VisibilityState = isVisible ? "visible" : isAlmostVisible ? "almost-visible" : "not-visible";

  return {
    top: scrollTop,
    visibility,
    scrollPercent,
    elementHeight,
    viewportHeight,
  };
}
