import { isSafari } from "../device-detect";

const skipWebm = isSafari();

/**
 * Get the actual video source to use for the given orientation
 * @param orientation
 * @returns a pair [url-string,mime-type], or undefined if no usable source was found.
 */
export function getVideoSourceForOrientation(
  media: HTMLVideoElement,
  orientation: "landscape" | "portrait"
): [string | undefined, string | undefined] {
  const sources = Array.from(media.querySelectorAll<HTMLSourceElement>("source"));
  let source: HTMLSourceElement | undefined = undefined;
  if (skipWebm) {
    /* Despite advertising that it _can_ play webm, Safari (at least as of 16.4) really
     * struggles with it, so prefer MP4 if at all possible. If we can't do MP4, we'll
     * accept other formats but add a hack below to let it load for longer (which seems
     * to help a little with the user experience).
     */
    source = sources.find(source => source.type === "video/mp4" && source.hasAttribute(`data-${orientation}`));
  }
  if (!source) {
    /* If we're not Safari, or we are but didn't find an MP4, just use the regular order (ie pick the first one that
     * the browser claims to support)
     */
    source = sources.find(
      source =>
        media.canPlayType(source.type) && source.hasAttribute(`data-${orientation}`) && (!skipWebm || source.type === "video/mp4")
    );
  }
  if (source) {
    return [resolveURL(source.getAttribute(`data-${orientation}`)), source.type];
  }
  return [undefined, undefined];
}

/**
 * A cheeky way to get the browser to resolve
 * any resolvable components in a URL
 * Fixes a bug where iPhones wouldn't play
 * videos with relative src URL
 */
export function resolveURL(url: string): string {
  const a = document.createElement("a");
  a.href = url;
  return a.href;
}

type VideoStateClass = "video-stopped" | "video-paused" | "video-playing" | "video-loading" | "video-error";
export function setVideoClass(state: VideoStateClass, videoplayer: HTMLElement): void {
  videoplayer.classList.remove("video-stopped", "video-paused", "video-playing", "video-loading", "video-error");
  videoplayer.classList.add(state);
}
