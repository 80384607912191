/**
 * Register a web component in the global custom elements registry if it hasn't already been registered
 */
export function registerWebComponent(name: string, component: CustomElementConstructor): void {
  /* Note: if we're embedded, the element might already have been defined. It may not be the exact same version,
   * but we're limited in what we can do here (unless we implement our own proxying etc)
   * Scoped registries would also make this problem go away (which seems to be actively progressing, at least)
   */
  if (!customElements.get(name)) {
    customElements.define(name, component);
  }
}
