import cx from "classnames/dedupe";

import once from "../utils/dom-event-once";

/**
 * WARNING! WARNING! WARNING!
 * DANGER ZONE!
 * This module is powered by UserAgent sniffing, which isn't always accurate.
 * Using anything this module provides should be a _last resort_, or if it's
 * wrong your implementation shouldn't fallover.
 */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Sniffr = require("sniffr");

/**
 * For reference of potential values provided by sniffer, check their source:
 * https://github.com/antivanov/sniffr/blob/97b47bc9cf37593b9362d383adf2808ace95d9da/src/sniffr.js#L3
 */
const MOBILE_OS = ["android", "ios", "firefoxos", "windows.phone", "windows.mobile", "blackberryos"];

interface State {
  hasTouch: boolean;
}
const state: State = { hasTouch: false };

interface DeviceInfo {
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isiPhone: boolean;
  isiPad: boolean;
  isAndroid: boolean;
  isiOS: boolean;
  isMac: boolean;
  isWindows: boolean;
  isIE: boolean;
  isEdge: boolean;
  isChrome: boolean;
  isSafari: boolean;
  isFirefox: boolean;
  isOpera: boolean;
  [key: string]: boolean;
}

let deviceInfo: DeviceInfo | null = null;

export function getDeviceInfo(): DeviceInfo {
  if (deviceInfo === null) {
    const sniffr = new Sniffr();
    sniffr.sniff(navigator.userAgent);
    deviceInfo = {
      // GROUPED CATEGORIES
      isMobile: MOBILE_OS.includes(sniffr.os.name),
      isDesktop: !MOBILE_OS.includes(sniffr.os.name),
      // Hard-coded false, what is a 'tablet' these days?
      isTablet: false,
      // APPLE DEVICES
      isiPhone: sniffr.device.name === "iphone",
      isiPad: sniffr.device.name === "ipad",
      // Operating Systems
      isAndroid: sniffr.os.name === "android",
      isiOS: sniffr.os.name === "ios",
      isiOS10: sniffr.os.name === "ios" && sniffr.os.version[0] === 10,
      isMac: sniffr.os.name === "macos",
      isWindows: sniffr.os.name === "windows",
      // Browsers
      isIE: sniffr.browser.name === "ie",
      isEdge: sniffr.browser.name === "edge",
      isChrome: sniffr.browser.name === "chrome",
      isSafari: sniffr.browser.name === "safari",
      isFirefox: sniffr.browser.name === "firefox",
      isOpera: sniffr.browser.name === "opera",
      // Bots
      isBot: /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent),
      // isTouch exluded from this API because it cannot be reliably determined at load time.
      isCypress: (window as any).Cypress,
    };
  }
  return deviceInfo;
}

export function addDeviceClassesToElement(element: Element): void {
  const deviceInfo: DeviceInfo = getDeviceInfo();
  const deviceClasses = Object.keys(deviceInfo).reduce((c, key) => {
    c[`DeviceDetect--${key}`] = deviceInfo[key];
    return c;
  }, {} as cx.ClassDictionary);

  element.className = cx(element.className, deviceClasses);
}

/**
 * Check if it's Safari. Note Chrome on iOS returns isSafari === false, even though it's really Safari,
 * so we also check for isiOS.
 */

export function isSafari(): boolean {
  const info = getDeviceInfo();
  return info.isSafari || info.isiOS;
}

export default function initDeviceDetect(rootElement = document.documentElement): void {
  addDeviceClassesToElement(window["__shadowRoot"]?.querySelector("article") || rootElement);
  listenForTouch();
}

export function listenForTouch(): void {
  once(document.documentElement, "touchstart", () => {
    state.hasTouch = true;
    document.documentElement.classList.add("DeviceDetect--isTouch");
  });
}
