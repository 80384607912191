import { debounce } from "ts-debounce";

import { isSafari } from "../device-detect";
import DisplayContainer from "../display-container";
import query from "../dom-helpers/query";
import { hideInstantImage } from "../instant-image";
import { fixIEObjectFitForElement } from "../object-fit";
import scrollwatch, { TriggerPoints, isVisible } from "../scrollwatch";
import once from "../utils/dom-event-once";
import { getVideoSourceForOrientation } from "../videoplayer/util";
import "./background-videos.scss";

interface IVideoSourceOptions {
  canControl: boolean;
}

export function showWhenPlaying(video: HTMLVideoElement): void {
  const showVideo = (): void => {
    video.style.opacity = "1";
  };
  const events = ["loadeddata", "playing", "canplaythrough"];
  events.forEach(event => once(video, event, showVideo));
}

function isPlaying(video: HTMLVideoElement): boolean {
  return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
}

function setVideoSource(video: HTMLVideoElement, options: IVideoSourceOptions = { canControl: true }): void {
  const isPortrait = DisplayContainer.getHeight() > DisplayContainer.getWidth();
  const wasPlaying = isPlaying(video);
  const [src] = getVideoSourceForOrientation(video, isPortrait ? "portrait" : "landscape");
  if (src && video.src !== src) {
    video.src = src;
    video.load();
    if (options.canControl && wasPlaying) {
      playVideo(video);
    }
  }
}

export default function initBackgroundVideos(): void {
  const useSafariHack = isSafari();

  const backgroundVideos = query("[data-background-video]");

  backgroundVideos.forEach(backgroundVideo => {
    const video = backgroundVideo.querySelector("[data-video]") as HTMLVideoElement;

    if (!video) return;

    // Safari has a problem with fixed background videos. It thinks they are playing
    // when they aren't so we load them as not fixed and then fix them after they can play.

    if (useSafariHack && video.className.includes("FullSize__fixedChild")) {
      video.addEventListener("canplay", () => {
        video.className = video.className.replace("FullSize__fixedChild", "FullSize__fixedChild--Safari");
      });
    }

    const autoPlay = video.autoplay;
    video.muted = true;
    video.defaultMuted = true;
    showWhenPlaying(video);

    function onVideoPlayable(): void {
      hideInstantImage(backgroundVideo);
      video.style.visibility = "visible";
      for (const fallbackImage of backgroundVideo.querySelectorAll("img")) {
        fallbackImage.style.visibility = "hidden";
      }
    }

    if (video.readyState >= 2) {
      /* This generally shouldn't happen, but handle it just in case */
      onVideoPlayable();
    } else {
      video.addEventListener("canplay", onVideoPlayable);
      video.addEventListener("error", () => hideInstantImage(backgroundVideo));
    }

    const onNear = (entry: IntersectionObserverEntry): void => {
      if (isVisible(entry)) {
        fixIEObjectFitForElement(video);
        setVideoSource(video, { canControl: false });
      }
    };
    scrollwatch(backgroundVideo, onNear, { triggerPoint: TriggerPoints.LazyloadModel });

    if (autoPlay) {
      const onTrigger = (entry: IntersectionObserverEntry): void => {
        if (isVisible(entry)) {
          /* Note: set the video source here as well in case we get fired before onNear */
          setVideoSource(video, { canControl: false });
          playVideo(video);
        } else {
          video.pause();
        }
      };

      scrollwatch(backgroundVideo, onTrigger, {
        triggerPoint: TriggerPoints.On,
      });

      // Force any videos near the top to play initially
      const { top } = backgroundVideo.getBoundingClientRect();
      if (top < DisplayContainer.getHeight() * 2) {
        setVideoSource(video, { canControl: false });
        playVideo(video);
        // Check on our video and see if it has actually started
        setTimeout(() => {
          if (video.readyState < 2 && video.paused) {
            video.load();
            playVideo(video);
          }
        }, 1000);
      }
    } else {
      setVideoSource(video, { canControl: false });
    }
  });

  const videoElements = query("[data-background-video] [data-video]");
  const refresh = debounce(
    (_event: UIEvent) =>
      videoElements.forEach((video: HTMLVideoElement) => {
        setVideoSource(video, { canControl: true });
      }),
    100
  );
  ["resize", "orientationchange"].forEach(event => {
    window.addEventListener(event, refresh, { passive: true });
  });
}

/**
 * Play a video.
 * @param video
 */
function playVideo(video: HTMLVideoElement): void {
  try {
    // NOTE: Safari in iOS 13 sometimes registers an apparently paused video as playing so let's force it to be paused first
    video.pause();
    //Fix for Safari displaying browser play button over paused video in autoplay. Hide video and display fallback image if paused
  } catch (err) {
    // Intentionally do nothing with the error, just have to catch it in case of the iOS Safari issue mentioned above.
  }

  const play = video.play();

  if (typeof play !== "undefined") {
    // Play might get interrupted
    play.catch(() => {
      // Try again?

      video.play().catch(err => {
        // If this one fails then just pause for now
        video.pause();
      });
    });
  }
}
